
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";
import { VueUploadItem } from "vue-upload-component";
import { reject } from "lodash";
import { readonly } from "vue-demi";
export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Title" },
      { field: "Category.Name" },
      { field: "Ordinal" },
      { field: "StartDate" },
      { field: "StartDate" },
      { field: "EndDate" },
      { field: "Published" }
    ];

    const gridOptions: GridOptions = {
      id: "user",
      title: "信件樣版",
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "信件樣版清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "信件樣版清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" },
          width: 180
        },
        {
          field: "Remark",
          title: "主旨",
          showHeaderOverflow: true,
          showOverflow: true
        }
      ],
      promises: {
        query: model
          ? params =>
              new Promise((resolve, reject) => {
                if (params && params.condition)
                  params.condition.and("Category", Operator.Equal, 60);
                model
                  .dispatch("template/query", params)
                  .then(payload => {
                    resolve(payload);
                  })
                  .catch(err => reject(err));
              })
          : undefined,
        queryAll: model ? () => model.dispatch("template/query") : undefined,
        save: model
          ? params => model.dispatch("template/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 200,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "Name",
          title: "名稱（不可異動）",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入標題", readonly: true }
          }
        },
        {
          field: "Remark",
          title: "主旨",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入說明" }
          }
        }
        // {
        //   field: "Content.Content",
        //   title: "信件內文",
        //   span: 24,
        //   itemRender: {
        //     name: "$textarea",
        //     props: { placeholder: "請輸入簡介", clearable: true }
        //   }
        // }
      ],
      rules: {
        Name: [{ required: true }],
        Remark: [{ required: true }]
      }
    };

    const editorOptions = {
      editor: ClassicEditor.Editor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      editorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = await this.$model.dispatch("template/find", row.Name);
      Object.assign(row, entity);
      callback();
    }
  }
});
